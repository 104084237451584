import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Box, Flex, Heading, Text, Parsed,
} from '../ui'
import TagList from './TagList'
import PostContent from './PostContent'
import parsePostContent from './parsePostContent'
import { WidgetEventCalendar, WidgetTodaysEvents } from '../sidebar'
import Ads from '../content/Ads'
import SidebarSubPage from '../sidebar/SidebarSubPage'

const PostTemplate = ({
  content,
  tags = [],
  title,
  meta,
  author,
  featuredImage,
}) => (
  <Box
    py={2}
    as="article"
  >
    <Ads placement="magnumSubPage" only="mobile"/>
    <Parsed
      as="h1"
      fontFamily="Merriweather, serif"
      fontSize={[4, 6]}
      lineHeight={1.05}
      component={Heading}
    >
      {title}
    </Parsed>

    {/* TODO */}
    <Flex
      py={2}
      mx={-2}
      fontSize={2}
      variant="text.muted"
      flexDirection="row"
      fontWeight="semibold"
    >
      <Text px={2}>
        {author.node.name}
      </Text>
      <Text>·</Text>
      <Text px={2}>
        {meta}
      </Text>
    </Flex>

    <Box
      mt={2}
      mb={4}
      mx={[-3, -3, -3, 0]}
    >
      <GatsbyImage
        alt={title}
        loading="eager"
        image={featuredImage}
      />
    </Box>
    <Ads placement="magnumSubPage" only="desktop"/>
    
    <PostContent>
      <Box
        display={['block', 'flex']}
      >
        <Box width={[1, 2 / 3]} mr={[0, 3]} mt={16}>
        {parsePostContent(content)}
        <Ads placement="zone2SubPage" only="mobile"/>
        <Ads placement="zone2SubPage" only="desktop"/>
        </Box>
        <Box width={[1, 1 / 3]}>
          <WidgetTodaysEvents />
          <WidgetEventCalendar />
          <SidebarSubPage />
        </Box>
      </Box>
      
    </PostContent>

    <TagList
      pt={3}
      tags={tags}
    />
  </Box>
)

export default PostTemplate
